import aboutus from '../../images/aboutus.png';

export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Power Up General Electrical Solutions',
    headline: 'About US',
    buttonLabel: 'LEARN MORE',
    imgStart: false,
    img: aboutus,
    alt: 'aboutus',
    dark: true,
    primary: true,
    darkText: false,
    hover: true
};

export const homeObjTwo = {
    id: 'WSPGallery galleryImages={galleryImages} ',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Unlimited Acess',
    headline: 'Unlimited Transactions with zero fees',

    description: 'Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees.',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: require('../../images/svg-1.svg').default,
    alt: 'Piggybank',
    dark: false,
    primary: false,
    darkText: true
};

export const LoginSection = {
    id: 'signup',
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Sign in',
    headline: 'Pag to Login ADMIN SECTION',

    description: 'Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees.',
    buttonLabel: 'Start Now',
    imgStart: false,
    img: require('../../images/svg-1.svg').default,
    alt: 'Paper',
    dark: true,
    primary: true,
    darkText: false
};
