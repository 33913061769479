import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/';
import Admin from './pages/admin';
import Test from './pages/test';
import { auth } from '../src/firebase'; 


function App({ component: Component, ...rest }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>

        <Route  path="/" element={<Home/>} />
        <Route  path="/test" element={<Test/>} />
        <Route path="/admin" element={isAuthenticated ? <Admin /> : <Navigate to="/test" />} />
      </Routes>
    </Router>
  );
}

export default App;
