import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import {
  Form1,
  Form,
  ContactContainer,
  ContactWrapper,
  ContactH1,
  Input,
  Input1,
  TextArea,
  LeftDiv,
  RightDiv,
  LocationDiv,
  MapDiv,
  Wrapper,
  Title

} from './ContactElements';
import { ButtonC } from '../ButtonElements';
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_MAPS_KEY, // Replace with your actual API key
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (message.trim().length !== 0) {
      console.log('input value is NOT empty');
    } else {
      console.log('input value is empty');
    }

    try {
      const contactRef = collection(db, 'contacts');
      await addDoc(contactRef, {
        to: 'powerup.egs@gmail.com',
        message: {
          subject,
          html: `<p>Client : ${name} </p>
          <p>Client email:${email} </p>
          <p>Phone:${phone}</p>
          <p>Message:${message}</p>`,
        },
      });

      // Clear form inputs
      setName('');
      setEmail('');
      setMessage('');
      setPhone('');
      setSubject('');

      // Display success message or redirect to a success page
      alert('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message:', error);
      // Display error message or handle error case
    }
  };

  return (
    <ContactContainer id="contacts">
       <Wrapper>
      <Title>leave a message</Title>
      <ContactH1>Contact Me</ContactH1>
      <ContactWrapper>
        
        <RightDiv>
          
          <Form1 onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <Form>
              <Input1
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Input1
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />  
            </Form>

            <Input
              type="text"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
            <TextArea
              value={message}
              type="text"
              placeholder="Message"
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            <br />
            <ButtonC type="submit">Send Message</ButtonC>
          </Form1>
        </RightDiv>
        <LeftDiv>
          <LocationDiv>
            <h3><FaMapMarkerAlt  />&ensp;&ensp;Location</h3>
            <p>474 Forest st,</p>
            <p>Unit 11
              Kearny NJ
              07032</p>
            <h3><FaPhoneAlt />&ensp;&ensp;Phone</h3>
            <p>+1 856-278-6073</p>
           
          </LocationDiv>
          <MapDiv >
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%' }}
              center={{ lat: 40.76919115572237, lng: -74.13989424705507 }}
              zoom={15}
            >
              <Marker
                position={{ lat: 40.76919115572237, lng: -74.13989424705507 }}
                options={{
                  text: {
                    value: 'Powerup',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  },
                  color: 'black',
                }}
            />
            </GoogleMap>
          ) : (
            <></>
          )}
          </MapDiv>
        </LeftDiv>
      </ContactWrapper>
      </Wrapper>
    </ContactContainer>
  );
};

export default Contact;
