import React, { useState, useEffect } from 'react';
import { 
  ProjectsContainer, 
  ProjectsH1, 
  ProjectsWrapper, 
  ProjectsCard, 
  ProjectsIcon, 
  ProjectsH2, 
  ProjectsIcon2, 
  StyledCarousel, 
  ProjectsH4 
} from '../ProjectsElements';
import { Modal } from '../Modal/index';
import VideoPopup from '../Modal/videop';
import { collectionGroup, getDocs, query } from 'firebase/firestore';
import { db } from '../../firebase';
import { FaPlayCircle } from 'react-icons/fa';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const Projects = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [images, setImages] = useState({});

  const openModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  useEffect(() => {
    loadAllImages();
  }, []);

  const loadAllImages = async () => {
    try {
      const collections = [
        'Moxxy Hotel',
        'After THC NYC',
        'Brooklyn Lab',
        'Nick House',
        'Tommy Office',
        'Ralph Lauren',
        'Summit House',
        'Chandelier',
        'Citibank',
        'George',
        'Burlington',
        'Grounding',
        'Public Storage',
        '7Eleven Dollar Tree',
        'Walmart',
        'Ev Charger Station',
      ];

      const fetchImageUrls = async (collection) => {
        const querySnapshot = await getDocs(query(collectionGroup(db, collection)));
        return querySnapshot.docs.map((doc) => doc.data().imageUrl);
      };

      const imagePromises = collections.map((collection) => fetchImageUrls(collection));
      const imageArrays = await Promise.all(imagePromises);

      const imagesObj = {};
      for (let i = 0; i < collections.length; i++) {
        imagesObj[collections[i]] = imageArrays[i];
      }

      setImages(imagesObj);
    } catch (error) {
      console.error('Error loading images:', error);
    }
  };

  return (
    <ProjectsContainer id="projects">
      <ProjectsH1>Electrical Installation</ProjectsH1>
      <Modal showModal={showModal} setShowModal={setShowModal} modalContent={modalContent} />
      {images && (
        <StyledCarousel>
          <ProjectsWrapper itemsToShow={1} breakPoints={breakPoints}>
            {/* Moxxy Hotel */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Moxxy Hotel'] && images['Moxxy Hotel'][10]} onClick={() => openModal('MoxxyHotel')} />
              </ProjectsIcon2>
              <VideoPopup videoToShow="video1" />
              <ProjectsH2>Moxy Hotel</ProjectsH2>
            </ProjectsCard>
            {/* NYC THC M */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['After THC NYC'] && images['After THC NYC'][46]} onClick={() => openModal('Thcnycba')} />
              </ProjectsIcon2>
              <VideoPopup videoToShow="video3" />
              <ProjectsH2>Nyc Thc Museum</ProjectsH2>
            </ProjectsCard>
            {/* Brooklyn Lab */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Brooklyn Lab'] && images['Brooklyn Lab'][13]} onClick={() => openModal('Bklabba')} />
              </ProjectsIcon2>
              <VideoPopup videoToShow="video2" />
              <ProjectsH2>Brooklyn Lab</ProjectsH2>
            </ProjectsCard>
            {/* Nick House */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Nick House'] && images['Nick House'][3]} onClick={() => openModal('NickHouse')} />
              </ProjectsIcon2>
              <ProjectsH4>
                <FaPlayCircle className="playIcon" />WATCH A VIDEO
              </ProjectsH4>
              <ProjectsH2>NickHouse</ProjectsH2>
            </ProjectsCard>
            {/* Tommy Office */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Tommy Office'] && images['Tommy Office'][3]} onClick={() => openModal('TommyOffce')} />
              </ProjectsIcon2>
              <ProjectsH4>
                <FaPlayCircle className="playIcon" />WATCH A VIDEO
              </ProjectsH4>
              <ProjectsH2>Tommy Offce</ProjectsH2>
            </ProjectsCard>
            {/* Ralph Lauren */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Ralph Lauren'] && images['Ralph Lauren'][3]} onClick={() => openModal('RalphLauren')} />
              </ProjectsIcon2>
              <ProjectsH4>
                <FaPlayCircle className="playIcon" />WATCH A VIDEO
              </ProjectsH4>
              <ProjectsH2>Ralph Lauren</ProjectsH2>
            </ProjectsCard>
            </ProjectsWrapper>
            <ProjectsWrapper itemsToShow={2} breakPoints={breakPoints}>
            {/* Summit House */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Summit House'] && images['Summit House'][3]} onClick={() => openModal('SummitHouse')} />
              </ProjectsIcon2>
              <ProjectsH4>
                <FaPlayCircle className="playIcon" />WATCH A VIDEO
              </ProjectsH4>
              <ProjectsH2>Summit House</ProjectsH2>
            </ProjectsCard>
            {/* Chandelier */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Chandelier'] && images['Chandelier'][3]} onClick={() => openModal('Chandelier')} />
              </ProjectsIcon2>
              <ProjectsH4>
                <FaPlayCircle className="playIcon" />WATCH A VIDEO
              </ProjectsH4>
              <ProjectsH2>Chandelier</ProjectsH2>
            </ProjectsCard>
            {/* Citibank */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Citibank'] && images['Citibank'][3]} onClick={() => openModal('Citibank')} />
              </ProjectsIcon2>
              <ProjectsH4>
                <FaPlayCircle className="playIcon" />WATCH A VIDEO
              </ProjectsH4>
              <ProjectsH2>Citibank</ProjectsH2>
            </ProjectsCard>
            {/* George */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['George'] && images['George'][3]} onClick={() => openModal('George')} />
              </ProjectsIcon2>
              <ProjectsH4>
                <FaPlayCircle className="playIcon" />WATCH A VIDEO
              </ProjectsH4>
              <ProjectsH2>George</ProjectsH2>
            </ProjectsCard>
            {/* Burlington */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Burlington'] && images['Burlington'][3]} onClick={() => openModal('Burlington')} />
              </ProjectsIcon2>
              <ProjectsH4>
                <FaPlayCircle className="playIcon" />WATCH A VIDEO
              </ProjectsH4>
              <ProjectsH2>Burlington</ProjectsH2>
            </ProjectsCard>
            {/* Grounding */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Grounding'] && images['Grounding'][9]} onClick={() => openModal('Grounding')} />
              </ProjectsIcon2>
              <ProjectsH4>
                <FaPlayCircle className="playIcon" />WATCH A VIDEO
              </ProjectsH4>
              <ProjectsH2>Grounding</ProjectsH2>
            </ProjectsCard>
            </ProjectsWrapper>
            <ProjectsWrapper itemsToShow={3} breakPoints={breakPoints}>
            {/* Public Storage */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Public Storage'] && images['Public Storage'][3]} onClick={() => openModal('PublicStorage')} />
              </ProjectsIcon2>
              <ProjectsH4>
                <FaPlayCircle className="playIcon" />WATCH A VIDEO
              </ProjectsH4>
              <ProjectsH2>Public Storage</ProjectsH2>
            </ProjectsCard>
            {/* Dollar Tree */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['7Eleven Dollar Tree'] && images['7Eleven Dollar Tree'][3]} onClick={() => openModal('SevenElevenDollarTree')} />
              </ProjectsIcon2>
              <ProjectsH4>
                <FaPlayCircle className="playIcon" />WATCH A VIDEO
              </ProjectsH4>
              <ProjectsH2>Dollar tree</ProjectsH2>
            </ProjectsCard>
            {/* Walmart */}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Walmart'] && images['Walmart'][3]} onClick={() => openModal('Walmart')} />
              </ProjectsIcon2>
              <ProjectsH4>
                <FaPlayCircle className="playIcon" />WATCH A VIDEO
              </ProjectsH4>
              <ProjectsH2>Walmart</ProjectsH2>
            </ProjectsCard>
            {/* Ev Charger Station*/}
            <ProjectsCard>
              <ProjectsIcon2>
                <ProjectsIcon src={images['Ev Charger Station'] && images['Ev Charger Station'][17]} onClick={() => openModal('EvChargerStation')} />
              </ProjectsIcon2>
              <VideoPopup videoToShow="video1" />
              <ProjectsH2>Ev Charger Station</ProjectsH2>
            </ProjectsCard>
          </ProjectsWrapper>
        </StyledCarousel>
      )}
      <br></br>
      <br></br>
      <br></br>
    </ProjectsContainer>
  );
};

export default Projects;
