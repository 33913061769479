import React, { useState } from 'react'
import Video from '../../videos/homep.mp4'
import { Button, ButtonTT } from '../ButtonElements'
import { 
    HeroContainer, 
    HeroBg, 
    VideoBg, 
    HeroContent, 
    HeroH1, 
    HeroP, 
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight,
    HeroLink,

} from './HeroElements'

const HeroSection = () => {
    
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    };
        
    return (
        
     <HeroContainer id="Home">
       

            <HeroBg  >
                <VideoBg autoPlay loop muted src=
                 {Video} type='video/mp4' />
            </HeroBg>
           
            
            <HeroContent>
           
                <HeroH1>Get top-notch electrical solutions.</HeroH1>
                
                <HeroP>
                    With our fast team of highly-trained electricians and and general construction,
                    we offer a personal, bespoke service that is fully tailored to your needs.
                </HeroP>
                
            <HeroBtnWrapper>
                    <HeroLink to='contacts'>
                    <Button onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' >
                        Give us a call or leave a message. {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                    </HeroLink>
                    
                   
                </HeroBtnWrapper>
                <HeroLink to='thumbtack'>
                    <ButtonTT/>
                    </HeroLink>
                 
            </HeroContent>
    </HeroContainer>
    );
};

export default HeroSection