import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from 'react-router-dom';
import {
    Form1,
    Form,
    ContactContainer,
    ContactWrapper,
    ContactH1,
    Input1,
    RightDiv,
    Wrapper,
    LoginLogo,
    Title
  
  } from '../components/Login/LoginElements';

const Test = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [ , setIsCurrentPage] = useState(false);

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                if (user.emailVerified) {
                    setIsCurrentPage(true); // Set current page state to true
                    navigate("/admin"); 
                } else {
                    sendEmailVerification(auth.currentUser)
                        .then(() => {
                            // Email sent.
                            setError("Please verify your email. A verification email has been sent.");
                            console.log("Verification email sent.");
                        })
                        .catch((error) => {
                            setError(error.message);
                            console.error(error.code, error.message);
                        });
                }
            })
            .catch((error) => {
                setError(error.message);
                console.error(error.code, error.message);
            });
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            
            
            <ContactContainer>
               <Wrapper>
               <LoginLogo/>
            <Title>Admin Page</Title>
            <ContactH1>Login</ContactH1>
            <ContactWrapper>
                    <Form>
                    <RightDiv>
                    <Form1 >
                            <label htmlFor="email-address">Email      </label>
                            <Input1
                                id="email-address"
                                name="email"
                                type="email"
                                required
                                placeholder="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form1>

                        <Form1 >
                            <label htmlFor="password">Password</label>
                            <Input1
                                id="password"
                                name="password"
                                type="password"
                                required
                                placeholder="Password"
                                value={password}
                                
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form1 >
                        <div>
                            <button onClick={onLogin}>Login</button>
                        </div>
                        {error && <div>{error}</div>}
                        </RightDiv>
                        </Form>
                        </ContactWrapper>
                </Wrapper>
            </ContactContainer>
            <Footer />
        </>
    );
};

export default Test;
