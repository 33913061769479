import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { 
    homeObjOne
} from '../components/InfoSection/Data';
import Services from '../components/Services';
import Footer from '../components/Footer';
import Contacts from '../components/Contacts';
import Thumbtack from '../components/Thumbtack';
import Clients from '../components/Clients';


const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };


    return (
        <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar  toggle={toggle} />
        <HeroSection />
        <InfoSection {...homeObjOne} />      
        <Services /> 
        <Thumbtack />  
        <Contacts />
        <Clients />
        <Footer />
        </>
    );
};


export default Home;