import React, {useState} from 'react';
import Popup from 'reactjs-popup';
import {ProjectsH3}from '../ProjectsElements'
import styled from 'styled-components';
import { FaPlayCircle } from 'react-icons/fa';


const StyledPopup = styled(Popup)`
&-content {
    background: transparent;
    border: none;
    width: 250px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0.4em #83c441, 0 0 0.4em #83c441;
    
}
`;

const Video = styled.video`
  width: 250px;
  height: 450px;
`;

const CloseModalButton = styled.button`
    cursor: pointer;
    position: absolute;
    top: 450px;
    
  

`;

const VideoPopup = ({ videoToShow }) => {
  const [showPopup, setShowPopup] = useState(false);
 

  const openPopup = () => {
    
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <ProjectsH3 onClick={openPopup}><FaPlayCircle className='playIcon' />WATCH A VIDEO</ProjectsH3>
      
      <StyledPopup open={showPopup} modal onClose={closePopup}>
        {videoToShow === 'video1' && (
          <Video controls>
            <source src="https://firebasestorage.googleapis.com/v0/b/power-up---gallery.appspot.com/o/videos%2Fmoxy.mp4?alt=media&token=4c175da5-900c-4484-a78e-0fe621e9a195" type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        )}
        {videoToShow === 'video2' && (
          <Video controls>
            <source src="https://firebasestorage.googleapis.com/v0/b/power-up---gallery.appspot.com/o/videos%2FBK%20lab.mp4?alt=media&token=35c7b179-7007-4378-8f7c-f9af21e8840a" type="video/mp4" />
            Your browser dsoes not support the video tag.
          </Video>
        )}
        {videoToShow === 'video3' && (
          <Video controls>
            <source src="https://firebasestorage.googleapis.com/v0/b/power-up---gallery.appspot.com/o/videos%2Fnyc.mp4?alt=media&token=561624d8-1170-4f24-b478-014a67c11959" type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        )}
        <CloseModalButton onClick={closePopup}>Close</CloseModalButton>
      </StyledPopup>
    </>
  );
};

export default VideoPopup;
  