import React, {useState, useEffect} from 'react'
import { 
    ProjectsContainer, 
    ProjectsH1, 
    ProjectsWrapper, 
    ProjectsCard, 
    ProjectsIcon, 
    ProjectsH2,
    ProjectsIcon2,
    StyledCarousel,
    ProjectsH4
} from '../ProjectsElements'
import {Modal} from '../Modal/index';
import { collectionGroup, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import { FaPlayCircle } from 'react-icons/fa';



const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];
  const Projects = () => {

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [images, setImages] = useState({});
  
    const openModal = (content) => {
      setModalContent(content);
      setShowModal(true);
    };
  
    useEffect(() => {
      loadAllImages();
    }, []);
  
    const loadAllImages = async () => {
      try {
        const collections = [
          "KFC River Head",
          "KFC Taco Bell Norwalk CT",
          "KFC Derby CT",
          "Popeyes Westfall Township",
        ];
  
        const fetchImageUrls = async (collection) => {
          const querySnapshot = await getDocs(query(collectionGroup(db, collection)));
          return querySnapshot.docs.map((doc) => doc.data().imageUrl);
        };
  
        const imagePromises = collections.map((collection) => fetchImageUrls(collection));
        const imageArrays = await Promise.all(imagePromises);
  
        const imagesObj = {};
        for (let i = 0; i < collections.length; i++) {
          imagesObj[collections[i]] = imageArrays[i];
        }
  
        setImages(imagesObj);
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };
    
  return (
    <ProjectsContainer id="projects">
        <ProjectsH1>Interior Painting</ProjectsH1>
        <Modal showModal={showModal} setShowModal={setShowModal} modalContent={modalContent} />  
        {images && (
            <StyledCarousel>
                <ProjectsWrapper itemsToShow={1} breakPoints={breakPoints} >
                    
                    <ProjectsCard>
                        < ProjectsIcon2 >
                            < ProjectsIcon src={images['KFC River Head'] && images['KFC River Head'][0]} onClick={() => openModal('KFCRiverhead')}/>
                        </ProjectsIcon2>
                       <ProjectsH4 ><FaPlayCircle className='playIcon' />WATCH A VIDEO</ProjectsH4>
                        <ProjectsH2>KFC River Head</ProjectsH2>
                    </ProjectsCard>
                    <ProjectsCard>
                        < ProjectsIcon2 >
                            < ProjectsIcon src={images['KFC Taco Bell Norwalk CT'] && images['KFC Taco Bell Norwalk CT'][33]} onClick={() => openModal('KFCTBNorwalkCT')}/>
                        </ProjectsIcon2>
                       <ProjectsH4 ><FaPlayCircle className='playIcon' />WATCH A VIDEO</ProjectsH4>
                        <ProjectsH2>KFC Taco Bell Norwalk CT</ProjectsH2>
                    </ProjectsCard>
                    <ProjectsCard>
                        < ProjectsIcon2 >
                            < ProjectsIcon src={images['KFC Derby CT'] && images['KFC Derby CT'][3]} onClick={() => openModal('KFCDerbyCT')}/>
                        </ProjectsIcon2>
                       <ProjectsH4 ><FaPlayCircle className='playIcon' />WATCH A VIDEO</ProjectsH4>
                        <ProjectsH2>KFC Derby CT</ProjectsH2>
                    </ProjectsCard>
                    <ProjectsCard>
                        < ProjectsIcon2 >
                            < ProjectsIcon src={images['Popeyes Westfall Township'] && images['Popeyes Westfall Township'][1]} onClick={() => openModal('PopeyesWestfallT')}/>
                        </ProjectsIcon2>
                       <ProjectsH4 ><FaPlayCircle className='playIcon' />WATCH A VIDEO</ProjectsH4>
                        <ProjectsH2>Popeyes Westfall Township</ProjectsH2>
                    </ProjectsCard>
                </ProjectsWrapper>
        </StyledCarousel>
        )}
        <br></br><br></br><br></br>

    </ProjectsContainer>
    
  )
}

export default Projects