import styled from 'styled-components';
import logo from '../../images/logo.png';

export const ContactContainer = styled.div`
    background: #050505;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 1000px;
    position: relative;
    z-index: 1;
    width: 100%;
    
    }

`;

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr
  
`;

export const ContactWrapper = styled.div`
    width: 1200px;
    max-height: 600px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    
    padding: 0 250px;
    z-index: 3;
    justify-content: center;

    @media screen and (max-width: 1280px) {
      
      padding: 0 20px;
      width: 400px;
  }

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      padding: 0 50px;
      width: 100px;
      
  }


`;

export const ContactH1 = styled.h1`
    width: 1200px;
    font-size: 6rem;
    color: #FFF;
    margin-bottom: 50px;
    border-bottom: 5px solid #83c441;
    border-size: 5px;
    text-align: center;
    padding-bottom: 10px;

    @media screen and (max-width: 1280px) {
        font-size: 5rem;
        width: 600px;
        
        
    }

    @media screen and (max-width: 1024px) {
        font-size: 5rem;
        width: 600px;
        
      
    }

    @media screen and (max-width: 768px) {
        font-size: 5rem;
        width: 100%;
       
    }

    @media screen and (max-width: 480px) {
      font-size: 5rem;
      width: 350px;
      
    }
`;


export const Title = styled.h1`
    width: 100%;
    font-size: 2rem;
    font-weight: 400;
    color: #83c441;
    
    text-align: center;
    padding-bottom: 10px;

    @media screen and (max-width: 1200px) {
      font-size: 1.5em;
      font-weight: 400;
      padding-top: 50px;
        
    }

    @media screen and (max-width: 744px) {
        font-size: 1.5rem;
        
        
    }

    @media screen and (max-width: 414px) {
        font-size: 1.5em;
        font-weight: 400;
        padding-top: 50px;
        
    }

    @media screen and (max-width: 390px) {
      font-size: 1.5em;
        font-weight: 400;
        padding-top: 50px;  
      
    }
`;

export const AlertContainer = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 0.75rem 1rem;
    line-height: 1.5;
    color: #1a237e;
    background-color: #e3f2fd;
    border-radius: 0.25rem;
    margin-bottom: 0.625rem;
    text-align: center;

`;
// RightDiv Contact ME
export const RightDiv = styled.div`
  width: 700px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  padding: 10px;
  border-left: 2px solid #ddd;
  border-radius: 10px 0px 0px 10px;

  @media screen and (max-width: 1024px) {
    width:550px;
    height:400px;
    padding: 0;
    border-right: none;
    padding: 20px;
    border-bottom: 2px solid #ddd;
    border-radius: 10px 10px 0px 0px;
}

  @media screen and (max-width: 744px) {
    width:400px;
    height:400px;
    padding: 0;
    border-right: none;
    padding: 10px;
    border-bottom: 2px solid #ddd;
    border-radius: 10px 10px 0px 0px;
  }

  @media screen and (max-width: 615px) {
    width:300px;
    height:400px;
    padding: 0;
    border-right: none;
    padding: 5px;
    border-bottom: 2px solid #ddd;
    border-radius: 10px 10px 0px 0px;
  }
`;

export const Form = styled.div`
  grid-template-columns: 1fr 1fr ;
  display: grid;
  align-items: center;
  margin: 0 auto;
  grid-gap: 5px;
  padding: 0 150px;
    
  @media screen and (max-width: 1024px) {
    grid-gap: 5px;
    padding: 0 50px;
  }

  @media screen and (max-width: 744px) {
    grid-gap: 5px;
    padding: 0 50px;

  }

  @media screen and (max-width: 615px) {
    grid-gap: 2px;
    padding: 0 30px;

`;

export const Form1 = styled.form`
  align-items: center;
  grid-gap: 1px;
  margin: 0 auto;
  font-size: 20px;
  text-align: center;

  @media screen and (max-width: 1024px) {
    margin-right: 150px;
  }

  @media screen and (max-width: 744px) {
    margin-right: 30px;
  }

  @media screen and (max-width: 615px) {
    margin-right: 10px;
  }
  
`;

export const Input = styled.input`
  padding: 0.5rem;
  width: 500px;
  border-radius: 10px;
  border: 1px solid #ddd;
  border-text: 1px solid #ddd;
  text-align: top;

  @media screen and (max-width: 1024px) {
    width: 420px;
  }

  @media screen and (max-width: 744px) {
    width: 280px;
  }

  @media screen and (max-width: 615px) {
    width: 215px;
  }

`;

export const Input1 = styled.input`
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  width: 200px;
  border-radius: 10px;
  border: 1px solid #ddd;

  @media screen and (max-width: 1024px) {
    width: 205px;
  }

  @media screen and (max-width: 744px) {
    width: 130px;
  }

  @media screen and (max-width: 615px) {
    width: 100px;
  }

`;

export const TextArea = styled.textarea`
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  border: 1px solid #ddd;
  resize: none;
  height: 200px;
  width: 500px;
  margin-top: 0.5rem;
    
  @media screen and (max-width: 1024px) {
    height: 100px;
    width: 420px;
  }

  @media screen and (max-width: 744px) {
    height: 100px;
    width: 280px;
  }

  @media screen and (max-width: 615px) {
    height: 100px;
    width: 215px;
  }

`;

// Left Div Location and Map
export const LeftDiv = styled.div`
  width: 200px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  padding: 10px;
  border-right: 2px solid #ddd;
  border-radius: 0px 10px 10px 0px;
  

  @media screen and (max-width: 1024px) {
    width:550px;
    height: 150px;
    padding: 0;
    border-right: none;
    border-bottom: 2px solid #ddd;
    flex-direction: row;
    border-radius: 0px 0px 10px 10px;
  }

  @media screen and (max-width: 744px) {
    width:400px;
    height:200px;
    border-radius: 0px 0px 10px 10px;
  }

  @media screen and (max-width: 615px) {
    width:300px;
    height:200px;
    border-radius: 0px 0px 10px 10px;
  }

`;

export const LocationDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: #555555;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 1024px) {
    width:150px;
    margin-right: 20px;
    margin-top: 30px;
    align-items: center;
  }

  @media screen and (max-width: 615px) {
    width:100px;
    margin-right: 20px;
    margin-top: 20px;
  }

`;

export const MapDiv = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 1024px) {
    width:  350px;
    height: 130px;
    flex-direction: row;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 744px) {
    width:  200px;
    height: 150px;
    flex-direction: row;
    margin-bottom: 2px;
  }

  @media screen and (max-width: 615px) {
    width:  150px;
    height: 150px;
    flex-direction: row;
    margin-bottom: 2px;
  }

`;
export const LoginLogo = styled.div`
    color: red;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 175px;
    margin-top: 5px;
    margin-bottom: 55px;
    font-weight: bold;
    text-decoration: none;
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-size: contain;
    width: 150px;
    width: 250px;
    height: 50px;
`;
