import styled from "styled-components";

export const ServicesContainer = styled.div`
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #050505;


    @media screen and (max-width: 480px) {
        height: 1500px;
    }

`;

export const ServicesWrapper = styled.div`
   
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 10px;
    
    
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-gap: 1px;
        max-width: 412px;
        padding: 0px;
    }

    @media screen and (max-width: 390px) {

        grid-template-columns: 1fr;
        grid-gap: 1px;
        max-width: 400px;
        padding: 0px;
    }
`;

export const ServicesCard = styled.div`
    text-color: #fff;  
    display: flex;
    height: 250px;
    width: 250px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    padding-top: 20px;
    
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }



    @media screen and (max-width: 768px) {
        height: 220px;
        width: 220px;

    }

`;

export const ServicesIcon = styled.img`
    height: 160px;
    width: 220px;
    margin-bottom: 20px;

    &:hover {

        box-shadow: 0 0 0.4em #83c441, 0 0 0.4em #83c441;
        text-shadow: 1px 1px 2px black, 0 0 1em #83c441, 0 0 0.2em #83c441;
    }

    


    @media screen and (max-width: 768px) {
        height: 140px;
        widht: 180px;

    }
`;

export const ServicesH1 = styled.h1`
    font-size: 6rem;
    color: #fff;
    margin-bottom: 10px;
    border-bottom: 5px solid #83c441;
    border-size: 5px;
    text-align: center;
    width: 850px;

    @media screen and (max-width: 1024px) {
        width: 750px;
        
        
    }

    @media screen and (max-width: 768px) {
        font-size: 5rem;
        width: 500px;
       
    }

    @media screen and (max-width: 480px) {
      font-size: 5rem;
      width: 350px;
      
    }
`;

export const ServicesH3 = styled.h3`
    font-size: 1.5rem;
    color: #83C441;
    margin-bottom: 14px;
    font-weight: 500;    

    @media screen and (max-width: 480px) {
        font-size: 1.3rem;
    }
`;

export const ServicesH2 = styled.h2`
    font-size: 1.6rem;
    margin-bottom: 10px;
    color: #fff;

    @media screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

export const ServicesP = styled.p`
    font-size: 1rem;
    text-align: center;
`;